// 严凯 V2.2.0
<template>
  <div ref="child">
    <a-modal centered
             title="查看图片"
             :width="800"
             :visible="visible"
             @cancel="$emit('update:visible', false)"
             :footer="null">
      <!-- @cancel="$emit('update:visible', false)" -->
      <a-carousel arrows
                  dots-class="slick-dots slick-thumb"
                  ref="carousel">

        <!-- <template #customPaging="props">
          <a>
            <img :src="photoList[props.i]"  @error="onError(props.i)" />
          </a>
        </template> -->
        <div v-for="(item,index) in state.imgList"
             :key="item">
          <img :src="item"
               @error="onError(index)" :style="{'transform':reverseImg?'rotateZ(180deg)':'rotateZ(0deg)'}"/>
        </div>
        <template #prevArrow>
          <div class="custom-slick-arrow"
               style="left: 10px;zIndex:1">
            <left-circle-outlined />
          </div>
        </template>
        <template #nextArrow>
          <div class="custom-slick-arrow"
               style="right: 10px">
            <right-circle-outlined />
          </div>
        </template>
      </a-carousel>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, onUpdated } from 'vue'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue'
import '@/style/jsx-label-text.scss'

export default defineComponent({
  components: {
    LeftCircleOutlined,
    RightCircleOutlined,
  },
  name: 'photo-view',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    photoList: {
      type: Array,
      default: [],
    },
    photoIndex: {
      type: Number,
      default: 0,
    },
    reverseImg:{
      type:Boolean,
      default:false,
    }
  },
  setup(props, ctx) {
    const carousel = ref(null)
    const state = reactive({
      imgList: [],
    })
    onUpdated(() => {
      if (props.visible) {
        state.imgList = props.photoList
          nextTick(() => {
          console.log(carousel);
          carousel.value.goTo(props.photoIndex)
        })
      } else {
        state.imgList = []
      }
    })
    
    const onError = (i) => {
      props.photoList[i] = require('@/assets/images/admin-sign.png')
    }
    return {
      carousel,
      onError,
      state,
    }
  },
})
</script>

<style lang="scss" scoped>
// .ant-carousel :deep(.slick-dots) {
//   position: relative;
//   height: auto;
// }
// .ant-carousel :deep(.slick-slide img) {
//   border: 5px solid #fff;
//   display: block;
//   margin: auto;
//   max-width: 80%;
// }
// .ant-carousel :deep(.slick-arrow) {
//   display: none !important;
// }
// .ant-carousel :deep(.slick-thumb) {
//   bottom: 0px;
// }
// .ant-carousel :deep(.slick-thumb li) {
//   width: 60px;
//   height: 45px;
// }
// .ant-carousel :deep(.slick-thumb li img) {
//   width: 100%;
//   height: 100%;
//   filter: grayscale(100%);
// }
// .ant-carousel :deep .slick-thumb li.slick-active img {
//   filter: grayscale(0%);
// }

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 56px;
  height: 56px;
  font-size: 56px;
  color: #000;
  opacity: 0.3;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:after) {
  display: none;
}
.ant-carousel :deep(.slick-slide img) {
  display: block;
  margin: auto;
  max-width: 80%;
}
.ant-carousel :deep(.slick-thumb) {
  display: none !important;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  margin-top: -28px;
}
</style>
